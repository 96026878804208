/* FullscreenImage.css */
.fullscreen-image {
    height: 100vh;
}

div.fullscreen-image {
    height: 100vh;
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.fullscreen .gen_pic{
    width: max-content;
    height: 100%;
    z-index: 1;
}
.left_arrow_block{
position: absolute;
    z-index: 999;
    left: 1%;
}
.right_arrow_block{
    position: absolute;
    z-index: 999;
    right: 1%;
}

.left_arrow, .right_arrow{
    width: 30px !important;
    height: 30px !important;
    z-index: 99;
}
.left_arrow{
   margin-right: 50% !important;
}
.close_icon_block{
    position: absolute;
    z-index: 99;
    right: 1%;
    top: 1%;
    width: 30px;
    height: 30px;
    display: flex;
    cursor: pointer;
    background-color: white;
    border: 2px solid #021B79;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close_icon{
    width: 15px;
    height: 15px;

}
@media(max-width: 1300px){
    .fullscreen .gen_pic{
        max-width: 900px;
        width: 100%;
      height: unset;
    }
}
@media(max-width: 900px){
    .fullscreen .gen_pic{
        max-width: 700px;
        width: 100%;
        height: unset;
    }
}

