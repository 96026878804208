.modalInside{
    display: flex;
    flex-direction: column;
    position: relative;
}

.closingModal{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    margin-bottom: 13px;
}

.modalTitle{
    color: #06177C;
    font-size:26px;
    font-family: Poppins_Bold,sans-serif;
    font-weight: 700;
    margin-bottom: 12px;
    text-align: center;
}

.modalText{
    color: #333;
    text-align: center;
    font-size: 18px;
    font-family: Poppins_Regular,sans-serif;
    margin-bottom: 12px;
}

.buttonsDiv{
    display: flex;
    justify-content: center;
    margin: auto;
    gap: 24px;
    flex-wrap: wrap;
}

.leftButton{
    width: 150px;
    border-radius: 30px;
    border: 1px solid #3F03A2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 26px;
    color: #3F03A2;
    font-size: 14px;
    font-family: Poppins_Medium,sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.rightButton{
    width: 150px;
    border-radius: 30px;
    background: #3F03A2;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 26px;
    cursor: pointer;
    color: #FFF;
    font-size: 14px;
    font-family: Poppins_Medium,sans-serif;
    font-weight: 500;
}
.close{
    width: 15px;
    height: 15px;
}

.takenPhotoDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalSubTitle{
    font-family: Poppins_Medium,sans-serif;
    color: #06177C;
    font-size:18px;
    text-align: center;
    margin-bottom: 15px;
}

.inputDiv input{
    border: 1px solid  #333;
    font-family: Poppins_Regular, sans-serif;
    color: #06177C;
    font-size:14px;
    text-align: center;
    border-radius: 30px;
    padding-top: 6px;
    padding-bottom:6px;
}
.inputDiv{
     align-items: center;
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-bottom: 7px;
}

.inputDiv input::placeholder{
    color: #333;
}

.sendName{
    border-radius: 30px;
    border: 1px solid #3F03A2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    color: #3F03A2;
    font-size: 14px;
    font-family: Poppins_Medium,sans-serif;
    font-weight: 500;
    cursor: pointer;
}

.inputDiv input:focus{
    outline: none;
}
@media only screen and (min-width:1024px) and (max-width: 1440px) {
    .modalText{
        max-width: 600px;
    }
}

@media only screen and (min-width:768px) and (max-width: 1023px) {
    .modalText{
        max-width: 400px;
    }
    .ReactModal__Content{
        padding: 20px !important;
        max-width: 600px !important;
        margin: auto;
    }
}

@media only screen and (min-width:425px) and (max-width: 767px) {
    .ReactModal__Content{
        padding: 20px !important;
        max-width: 400px !important;
        margin: auto;
    }

}

@media only screen and (min-width:325px) and (max-width: 424px) {
    .ReactModal__Content{
        padding: 10px !important;
        max-width: 300px !important;
        margin: auto;
    }
}


@media only screen  and (max-width: 768px) {
    .modalTitle {
        font-size: 20px;
    }
    .modalText {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .leftButton{
        padding: 16px 0;
        max-width: 140px;
        width: 100%;
    }
    .buttonsDiv{
        gap: 12px;
    }
    .inputDiv{
        flex-direction: column;
        margin-top: 8px;
    }
    .sendName{
        width: 100px;
    }
}

@media only screen and (min-width:600px) and (max-width: 767px) {
    .modalTitle {
        max-width: 590px;
        width: 100%;
        white-space:pre-wrap;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    }
    .modalText {
        max-width: 590px;
        width: 100%;
        white-space:pre-wrap;
        text-align: center;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media only screen and (min-width:425px) and (max-width: 600px) {
    .modalTitle {
        max-width: 400px;
        width: 100%;
        white-space:pre-wrap;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    }
    .modalText {
        max-width: 400px;
        width: 100%;
        white-space:pre-wrap;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media only screen and (max-width: 424px) {
    .modalTitle {
        max-width: 300px;
        width: 100%;
        white-space:pre-wrap;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    }
    .modalText {
        max-width: 320px;
        width: 100%;
        white-space:pre-wrap;
        margin: auto;
        padding-left: 5px;
        padding-right: 5px;
    }
}