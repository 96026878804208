* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}
body{
  min-height: 100vh;
}

html{
  scroll-behavior: smooth;
}

.container{
  max-width: 1760px;
  width: 100%;
  padding-left:15px;
  padding-right: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.swiper-wrapper{
  width: 100px !important;
  height: 150px !important;
}
.swiper-slide{
  width: 100px !important;
  height: 100px !important;
}
.swiper-button-prev{
  display: none;
}
.swiper-button-next{
  display: none
}

.imgGroup{
  width: 100%;
}
.imgGroup > .fullscreen-group{
  width: 100% !important;
}
@font-face {
  font-family: Poppins_Light;
  src: url(app/assets/fonts/poppins/Poppins-Light.ttf);
}

@font-face {
  font-family: Poppins_Thin;
  src: url(app/assets/fonts/poppins/Poppins-Thin.ttf);
}

@font-face {
  font-family: Poppins_Medium;
  src: url(app/assets/fonts/poppins/Poppins-Medium.ttf);
}

@font-face {
  font-family: Poppins_Regular;
  src: url(app/assets/fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins_SemiBold;
  src: url(app/assets/fonts/poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: Poppins_Bold;
  src: url(app/assets/fonts/poppins/Poppins-Bold.ttf);
}
