.whole{
    background: linear-gradient(180deg, #9E05E6 0%, #1D028A 84.79%, #021B79 100%);
    height: 100vh;
    overflow-y: auto;
    padding-bottom: 40px;
    /*position: relative;*/
}

.title{
    color:  #FFF;
    font-size: 40px;
    font-family: Poppins_Bold,sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 70px;
}

.subTitle{
    color:  #FFF;
    font-size: 18px;
    font-family: Poppins_Medium,sans-serif;
    font-weight: 500;
    margin-bottom: 62px;
    text-align: center;
}

.card{
    border-radius: 20px;
    background: #FFF;
    display: flex;
    width: 400px;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.card:hover{
    box-shadow: 20px 20px 20px 20px #06177C;
}

.cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
    overflow-y: auto;
    /*position: relative;*/
}

.cardTitle{
    color: #06177C;
    font-size:26px;
    font-family: Poppins_Bold,sans-serif;
    font-weight: 700;
    text-align: center;
}

.cardSubTitle{
    font-family: Poppins_Medium,sans-serif;
    color: #06177C;
    font-size:18px;
    text-align: center;
}

.cardText{
    color: #333;
    text-align: center;
    font-size: 18px;
    font-family: Poppins_Regular,sans-serif;
}

.cardImg{
    width:100px;
    height: 100px;
    margin-top: 12px;
}
.cardImg img{
    width:100px;
    height: 100px;
    object-fit: cover;
}

.swiperIn{
    width:100px;
}

.closeItem{
    width:40px;
    height:40px
}


@media only screen and (max-width: 768px) {
    .title{
        font-size: 32px;
        margin-top: 50px;
    }
    .subTitle{
        margin-bottom: 32px;
    }

    .cardTitle{
        font-size:24px;
    }

    .cardText{
        font-size: 16px;
    }
    .cards{
        gap: 14px;
    }
}
